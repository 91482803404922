import { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import request from "services/request";
import CustomTable from "components/customTable";
import { DEFAULT_ERROR_MESSAGE } from "common/constants";
import { useUserData } from "contexts/AuthContextManagement";
import { useLoading } from "contexts/LoadingContextManagement";
// occupancyTableColumns2 as tableColumns,
import { getDummyFilteredData, timezoneFormat, parseTime } from "common/utils";
import {
  parseData,
  getGuests,
  sumDailyOccupancy,
  getDatesInRange,
} from "./utils";
import occupancyGuestsDummy from "../../data/occupancy_guests.json";
import leftDailyOccupancyDummy from "../../data/daily_occupancy.json";

function LeftDailyOccupancy({ formData }) {
  console.log({formData})
  const { t } = useTranslation();
  const { setLoading, setError } = useLoading();
  const {
    selectedRestaurantId,
    selectedRestaurant,
    isRestaurantLoaded,
    hasRetaurants,
  } = useUserData();
  const [dates, setDates] = useState([]);
  const [tableData, setTableData] = useState([]);
  const prevFromData = useRef(formData)
  const updateLanguage = localStorage.getItem('fullsoon_i18nextLng');

  useEffect(async() => {
    if (selectedRestaurantId !== "" && typeof formData.start_date === "string" && prevFromData.current !== formData) {
      await getDailyOccupancy(formData.start_date, formData.end_date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, updateLanguage]);

  // useEffect(()=> {
  //   if (isRestaurantLoaded && !hasRetaurants) {
  //     //  const occupancyByDays = getDummyFilteredData(occupancyGuestsDummy,formData, selectedRestaurant?.timezone)
  //     //  transformTableData(occupancyByDays);
  //     // setTableData(leftDailyOccupancyDummy);
  //   }
  // },[formData, isRestaurantLoaded, hasRetaurants])

  const formatDate = (date) => {
    const options = { day: "2-digit", month: "long", year: "numeric" };
    return new Intl.DateTimeFormat("en-GB", options).format(date); // Ensure format matches JSON
  };

  const getDynamicWeekDates = () => {
    const today = new Date();
    const dates = [];
    // Last 3 days
    for (let i = -3; i < 0; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      dates.push(formatDate(date));
    }
    // Current day
    dates.push(formatDate(today));
    // Next 3 days
    for (let i = 1; i <= 3; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      dates.push(formatDate(date));
    }
  
    return dates;
  };
  
  useEffect(() => {
    if (isRestaurantLoaded && !hasRetaurants) {
    const dynamicDates = getDynamicWeekDates();
    // Filter and update JSON data to match dynamic dates
    const filteredData = dynamicDates.map((date) => {
      // Find matching data in JSON or create default
      const matchingData = leftDailyOccupancyDummy.find((item) => item.date === date) || {
        date,
        total_actual_occupancy: 0,
        total_predicted_occupancy: 0,
        breakfast: 0,
        lunch: 0,
        afternoon: 0,
        dinner: 0,
      };

      // Translate the month in the date
      const formattedDate = moment(matchingData.date).format("DD MMMM YYYY");
      const month = moment(matchingData.date).format("MMMM");
      const translatedMonth = t(month); // Use the translation function
      const translatedDate = formattedDate.replace(month, translatedMonth);

      return {
        ...matchingData,
        date: translatedDate,
      };
    });
  
    setTableData(filteredData);
    }
  }, [formData, isRestaurantLoaded, hasRetaurants, updateLanguage]);

  const transformTableData = (dailyOccupancies) => {
    const finalData = dailyOccupancies.map((ele) => {
      // Extract the month from the date and translate it
      const formattedDate = moment(ele.date).format("DD MMMM YYYY");
      const month = moment(ele.date).format("MMMM");
      const translatedMonth = t(month);
  
      // Replace the month in the formatted date with the translated month
      const translatedDate = formattedDate.replace(month, translatedMonth);
      return {
        date: translatedDate,
        total_actual_occupancy: ele.total_actual_occupancy,
        total_predicted_occupancy: ele.total_predicted_occupancy,
        ...getGuests(ele),
      };
    });
    setTableData(finalData);
  };

  const getDataBetweenDates = () => {
    const start_date = moment(formData.start_date);
    const end_date = moment(formData.end_date);
    let loop = moment(formData.start_date);
    while (loop.isBefore(end_date) || loop.isSame(end_date)) {
      const day = Number(loop.format("DD"));
      loop = loop.add(1, "day");
    }
  };

  const getDailyOccupancy = async (startDate, endDate) => {
    if (selectedRestaurantId === "") {
      return;
    }

    setLoading(true);

    try {
      const result = await request.get(
        "occupancy/guests",
        {
          ...timezoneFormat(startDate, endDate, selectedRestaurant?.timezone),
          restaurant_id: selectedRestaurantId,
        },
        true,
        true,
        true
      );
      const occupancyByDays = parseData(
        result.days,
        selectedRestaurant?.timezone
      );

      transformTableData(occupancyByDays);
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.status !== 499) {
        setError(DEFAULT_ERROR_MESSAGE);
        setLoading(false);
      }
    }
  };

  const occupancyTableColumns2 = [
    {
      dataField: "date",
      caption: "Date",
      style: { width: "150px" },
      headerStyle: {
        width: "150px",
        fontWeight: "700",
        textAlign: "center",
        fontSize: "19px"
      },
    },
    {
      dataField: "total_actual_occupancy",
      caption: t("TotalActual"),
      className: "text-center",
      headerClassName: "text-center",
      headerStyle: {
        fontWeight: "700",
        textAlign: "center",
        fontSize: "19px",
      },
      // isLower: (params) => (params < 10 ? "text-danger" : ""),
      // isHigher: (params) => (params > 50 ? "text-success" : ""),
    },
    {
      dataField: "total_predicted_occupancy",
      caption: t("TotalForecast"),
      className: "text-center",
      style: {color : "#6353ea"},
      headerClassName: "text-center",
      headerStyle: {
        color: "#6353ea",
        fontWeight: "700",
        textAlign: "center",
        fontSize: "19px"
      },
      // isLower: (params) => (params < 10 ? "text-danger" : ""),
      // isHigher: (params) => (params > 50 ? "text-success" : ""),
    },
    {
      dataField: "breakfast",
      caption: t("Breakfast"),
      className: "text-center",
      headerClassName: "text-center",
      headerStyle: {
        fontWeight: "700",
        textAlign: "center",
        fontSize: "19px"
      },
      // isLower: (params) => (params < 10 ? "text-danger" : ""),
      // isHigher: (params) => (params > 50 ? "text-success" : ""),
    },
    {
      dataField: "lunch",
      caption: t("Lunch"),
      className: "text-center",
      headerClassName: "text-center",
      headerStyle: {
        fontWeight: "700",
        textAlign: "center",
        fontSize: "19px"
      },
      // isLower: (params) => (params < 10 ? "text-danger" : ""),
      // isHigher: (params) => (params > 50 ? "text-success" : ""),
    },
    {
      dataField: "afternoon",
      caption: t("Afternoon"),
      className: "text-center",
      headerClassName: "text-center",
      headerStyle: {
        fontWeight: "700",
        textAlign: "center",
        fontSize: "19px"
      },
      // isLower: (params) => (params < 10 ? "text-danger" : ""),
      // isHigher: (params) => (params > 50 ? "text-success" : ""),
    },
    {
      dataField: "dinner",
      caption: t("Dinner"),
      className: "text-center",
      headerClassName: "text-center",
      headerStyle: {
        fontWeight: "700",
        textAlign: "center",
        fontSize: "19px"
      },
      // isLower: (params) => (params < 10 ? "text-danger" : ""),
      // isHigher: (params) => (params > 50 ? "text-success" : ""),
    },
    // ...timesData.map((ct) => ({
    //   dataField: ct,
    //   caption: ct,
    //   className: "text-center",
    //   headerClassName: "text-center",
    // })),
  ]; 

  let selectedTableColumns = occupancyTableColumns2?.map((c) => ({
    ...c,
    caption: t(c.caption),
  }));
  
  const filteredTableColumns = occupancyTableColumns2?.filter((c) =>
    Object.keys(formData)
      .filter((f) => formData[f])
      .includes(c.dataField)
  );
  
  // Ensure that "total_actual_occupancy" and "total_predicted_occupancy" are always included
  const alwaysVisibleColumns = ["total_actual_occupancy", "total_predicted_occupancy"];
  const mandatoryColumns = occupancyTableColumns2?.filter((c) => 
    alwaysVisibleColumns.includes(c.dataField)
  );
  
  if (filteredTableColumns.length) {
    selectedTableColumns = [
      occupancyTableColumns2[0],
      ...mandatoryColumns, 
      ...filteredTableColumns, 
    ];
  }
  
  return (
    <div className="leftcontent leftcontent-occupancy">
      <div className="main-container">
        <div className="card" style={{ height: "100%" }}>
          <div className="card-header d-flex align-items-center justify-content-between card-navbtns border-bottom">
            <h2 style={{ color: "#6353ea", fontWeight: "800", fontSize: "21px" }}>{t("Daily occupancy")}</h2>
          </div>
          <div className="card-body">
            <CustomTable columns={selectedTableColumns} data={tableData} tableName="dailyOccupancy"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftDailyOccupancy;