import React, { useState, useEffect, useReducer, useRef, useMemo } from "react";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { Dropdown, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { set } from "lodash";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap"
import CustomTable from "components/customTable/index.tsx";
import { useUserData } from "contexts/AuthContextManagement";
import { useLoading } from "contexts/LoadingContextManagement";
import { cloneDeep } from "common/utils.ts";
import StockEvolution from "./StockEvolution";
import EyeIcon from "assets/images/icon/eye.svg";


import {
  DEFAULT_ERROR_MESSAGE,
  ITEMS_PER_PAGE,
} from "common/constants";
import { getRandomNumber } from "views/occupancy/data";
import UploadModal from "../../stock/uploadModal";
import SortIcon from "assets/images/icon/SORT.svg";
import ArrowDownIcon from "assets/images/icon/arrow_down.svg";
import ArrowUpIcon from "assets/images/icon/arrow_up.svg";
import Trashicon from "assets/images/icon/filter.svg";
import useFetch from "customHooks/useFetch";
import sampleStockPred from "../../../data/labo_stock.json";
import stockProviders from "../../../data/stock_provider.json"
import reducer, { ACTION_TYPES, initialState } from "../../stock/reducer";
import OrderDetailsModal from "./OrderDetailsModal";
import request from "services/request";
import QuantityContainer from "components/quantityBtnContainer";
import CustomModal from "./orderModel";

function LeftSide({ formData, clear }) {
  const tableDataClone = useRef(null);
  const prevFromData = useRef(formData)
  const [state, dispatch] = useReducer(reducer, initialState);
  const [isStocksLevelDown, setIsStockLevelDown] = useState(false);
  const { setLoading, setError } = useLoading();
  const { t } = useTranslation();
  const {
    selectedRestaurant,
    selectedRestaurantId,
    isRestaurantLoaded,
    hasRetaurants,
    stockEvolution: stockEvolutionData,
    resetStock
  } = useUserData();
  const [tableColumns, setTableColumns] = useState([]);
  const [isModal, setIsModal] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [stocksLoading, setStocksLoading] = useState(true);
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [currentValue, setCurrentValue] = useState(0);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [sleaveState, setSleaveState] = useState({
    index: -1,
    isOpen: true,
  });
  const [fd, setfd] = useState({
    orderBy: "desc",
    sortBy: "stock.unit_stock",
  });

  const [stockValue, setStockValue] = useState([])
  const [filteredTableDataWithTotal, setFilteredTableDataWithTotal] = useState(
    []
  );
  const [activeProvider, setActiveProvider] = useState("select_provider");

  const { data: providersData } = useFetch(`providers`, {
    restaurant_id: selectedRestaurantId,
  });

  const [providersMap, providersArr] = useMemo(() => {
    const dataSource = hasRetaurants ? providersData : stockProviders;
    if (dataSource?.providers) {
      const prMap = dataSource.providers.reduce(
        (obj, current) => ({ ...obj, [current.id]: current.name }),
        { select_provider: t("select_provider") }
      );
      const prArr = [
        { id: "select_provider", name: t("select_provider") },
        ...dataSource.providers,
      ];
      return [prMap, prArr];
    }
    return [{}, []];
  }, [providersData, stockProviders]);

  const processedCurrentItems = useMemo(() => {
    const newCurrentItems = [...currentItems];
    if (sleaveState.index !== -1) {
      newCurrentItems[sleaveState.index] = {
        ...newCurrentItems[sleaveState.index],
        prediction: { isOpen: sleaveState.isOpen },
        start_date: formData.start_date,
        end_date: formData.end_date,
        time_zone: selectedRestaurant?.timezone,
      };
    }
    return newCurrentItems;
  }, [currentItems, sleaveState, resetStock]);

  useEffect(() => {
    const newData = [...processedCurrentItems];
    setFilteredTableDataWithTotal(newData);
  }, [processedCurrentItems]);

  const formatData = ({ ingredient_stock, isHist = false }, isDummy = false) => {

    return (ingredient_stock ?? []).map((i) => {
      const getName = () => i.name + (i.brand ? ` (${i.brand})` : "");
      const getQty = (n) => Number((n >= 0 ? n : 0).toFixed(2));
      const prevision = i?.stock_prediction?.prevision ?? 0
    
      if (isDummy) {
        i.stock.unit_stock = i?.stock?.unit_stock;
      } 

      let inventory = 0;
      let theoretical_stock = 0;
      let stock_gap = 0;

      let out_unit_total = 0;
      let in_unit_total = 0;
      let loss = 0;
 
      if (isHist) {
          out_unit_total = i.stock_log?.out_unit_total?? 0;
          in_unit_total = i.stock_log?.in_unit_total?? 0;
          loss = (i.stock_log ? (i.stock_log.in_unit_total - i.stock_log.out_unit_total)?.toFixed(2) : 0);
        } else {
          inventory = i.stock?.unit_stock?? 0;
          theoretical_stock = i.stock?.theoretical_stock?? 0;
          stock_gap = i?.stock ? (i?.stock?.unit_stock - i?.stock?.theoretical_stock)?.toFixed(2) : 0;
      }
    
      return {
        ...i,
        name: getName(),
        ...(i.stock === null && { stock: { unit_stock: 0 } }),
        ...(!i.qty_to_buy && {
          qty_to_buy: prevision
            ? getQty(prevision - (i?.stock?.unit_stock ?? 0))
            : 0,
        }),
        product_quantity: prevision
          ? getQty(
              Math.ceil((prevision - (i?.stock?.unit_stock ?? 0)) / i.format)
            )
          : 0,
        out_unit_total,
        in_unit_total,
        loss,

        stock: {
          unit_stock: inventory
        },
        theoretical_stock,
        stock_gap,
        ...(!i.stock_prediction && {
          stock_prediction: { prevision },
        }),
      };
    });
  };

  const fetchAndSetOrder = async () => {
    try {
      const result = await getOrders();
      result.purchase_orders.forEach((value) => {
        value.delievry_status = value.is_delivered
          ? "Delivered"
          : "Not Delivered";
      });
      setCurrentItems(result.purchase_orders);
      setPageCount(result.total_pages);
      setCurrentPage(result.page - 1);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  const showRow = (row) => () => {
    setSelectedIngredient(row);
    setModalShow(true);
  };

  const getOrders = (page = 1) => {
    const payload = {
      restaurant_id: selectedRestaurantId,
      limit: ITEMS_PER_PAGE,
      page,
      order_by: fd.orderBy.toUpperCase(),
      ...(fd.sortby && { sort_by: fd.sortby }),
      ...(fd.search && { search: fd.search }),
      ...(selectedProvider && { provider: selectedProvider.name }),
    };
    return request.get(`purchase-orders`, payload);
  };

  // Invoke when user click to request another page.
  const handlePageClick = async (ev) => {
    try {
      if (isRestaurantLoaded && !hasRetaurants) {
        const resultDummyData = getSampleStocks(ev.selected + 1);
        setStocksLoading(false);
        generateTableData(formatData(resultDummyData));
        setPageCount(resultDummyData.total_pages);
        setCurrentPage(ev.selected);
        return;
      }
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  const getStockColumns = () =>  {
    return [
      {
        label: t("RecipeName"),
        dataField: "product",
        type: "string",
        active: false,
      },
      {
        label : t("Quantity in stock"),
        dataField: "stock.unit_stock",
        type: "number",
        active: true,
        type: "customRender",
        render: (col, it) => {
          return <QuantityContainer style={{ width: "50%", margin: "auto" }} currentValue={it?.stock?.unit_stock} setCurrentValue={(value) => setFilteredTableDataWithTotal(prevFilteredTableData => {
           return prevFilteredTableData.map(item => {
            if(item.id === it.id) {
              return {
                ...item,
                stock: {
                  ...item.stock,
                  unit_stock: value
                }
              }
            }
            return item
           })
          })} />
        }
      },
      { 
        label: t("Unit"), 
        dataField: "unit", 
        type: "string", 
        active: true,
      },
      {
        label: t("Quantity To Produce"),
        dataField: "qty_to_buy",
        type: "number",
        active: true,
        type: "customRender",
        render: (col, it) => {
          return <QuantityContainer style={{ width: "50%", margin: "auto" }} currentValue={it?.qty_to_buy} setCurrentValue={(value) => setFilteredTableDataWithTotal(prevFilteredTableData => {
            return prevFilteredTableData.map(item => {
             if(item.id === it.id) {
               return {
                 ...item,
                 qty_to_buy: value
               }
             }
             return item
            })
           })} />
        }
      },
      {
        label: t("Details"),
        dataField: "action",
        caption: "Details",
        className: "text-center",
        headerClassName: "text-center custome-width",
        headerStyle: { width: "80px" },
        active : true,
        type: "customRender",
        render: (col, it) => (
            <img
              src={EyeIcon}
              className="cursor-pointer"
              alt="..."
              onClick={showRow(it)}
            />
          ),
        }
    ]
  }

  const cols = useMemo(() => getStockColumns(), [t, formData, currentItems]);
  useEffect(() => {
    generateTableColumns(cols)
  }, [cols])

  useEffect(() => {
    setSleaveState((p) => ({ ...p, index: -1, isOpen: false }));
  }, [fd, selectedRestaurantId, currentPage, activeProvider]);

  useEffect(() => {
    if (true) {
      setfd((prevFd) => ({ ...prevFd, sortBy: "product" }));
    }
  }, [true])

  useEffect(() => {
    if (clear) {
      setfd({
        orderBy: "desc",
        sortBy: "stock.unit_stock",
      })
      setActiveProvider('select_provider')
    }
  }, [clear])

  const getSampleInitialData = () => {
    const resultDummyData = getSampleStocks();
    setStocksLoading(false);
    generateTableData(formatData(resultDummyData));
    setPageCount(resultDummyData.total_pages);
    setCurrentPage(0);
  }

  useEffect(() => {
    if(isRestaurantLoaded && !hasRetaurants) {
      getSampleInitialData()
    }
  },[hasRetaurants, isRestaurantLoaded])

  const getSampleStocks = (page = 1) => {
    const DUMMY_DATA_PER_PAGE = 15;
    const offset = page * DUMMY_DATA_PER_PAGE - DUMMY_DATA_PER_PAGE;
    const sortKeys = {
      good: 3,
      soon: 2,
      no_expiry: 1,
      expired: 0,
    };

    const resultTotal = sampleStockPred
      .sort((a, b) => {
        const [first, second] = fd.sortBy.split(".");
        let aVal = a[first];
        let bVal = b[first];
        if (aVal && second) {
          aVal = aVal[second];
        }
        if (bVal && second) {
          bVal = bVal[second];
        }
        bVal = sortKeys[bVal] || bVal;
        aVal = sortKeys[aVal] || aVal;
        return fd.orderBy === "desc" ? bVal - aVal : aVal - bVal;
      })
      .filter((item) => {
        let res = true;
        // if (status?.length > 0) {
        //   if (item.stock) {
        //     res = status.includes(item.stock.expiry);
        //   } else {
        //     res = false;
        //   }
        // }
        // if (formData.products.length && res) {
        //   res = formData.products.includes(item.id);
        // }
        return res;
      });

    let result = resultTotal
      .filter(
        (item, index) =>
          index >= offset && index <= DUMMY_DATA_PER_PAGE + offset
      )
      .map((item) => {
        const start_date = moment(formData?.start_date).subtract(1, "day");
        const end_date = moment(formData?.end_date);
        const prediction = item.stock_prediction
          .filter(
            (pred) =>
              start_date.isSame(moment(pred.start_date)) ||
              end_date.isSame(moment(pred.end_date)) ||
              start_date.isBetween(
                moment(pred.start_date),
                moment(pred.end_date)
              )
          )
          .reduce(
            (total, num) => total + num.prevision || 0,
            getRandomNumber(0, 8)
          );
        return {
          ...item,
          stock_prediction: { prevision: Number(prediction.toFixed(2)) },
        };
      });
    if (page == 1) {
      result = result.splice(0, 13);
    } else if (page == 2) {
      result = result.splice(13, 26);
    }
    return {
      ingredient_stock: result,
      sort_by: fd.sortBy === "product" ? "name" : fd.sortBy,
      order_by: fd.orderBy.toUpperCase(),
      limit: DUMMY_DATA_PER_PAGE,
      page,
      total_pages: 2,
      total_results: resultTotal.length,
    };
  };

  const deleteItems = () => {
    let newTableData = tableDataClone?.current?.filter(
      (f) => !state.selectedItems.map((s) => s.id).includes(f.id)
    );
    tableDataClone.current = newTableData;
    setCurrentItems(() => newTableData);
    dispatch({
      type: ACTION_TYPES.REMOVE_SELECTED_ITEMS,
      items: state.selectedItems.map((s) => s.id),
    });
  };

  const generateTableColumns = (cols) => {
    const newTableColumns = [
      {
        dataField: "name",
        caption: t("RecipeName"),
        className: "fw-bold",
        style: { width: "150px" },
        headerStyle: { width: "265px" },
      },
      ...cols
        .filter((c) => c.active)
        .map(({ label, dataField, ...rest }) => ({
          ...rest,
          caption: label,
          dataField,
          className: `${dataField === "expiry" ? "" : "text-center"} ${
            dataField === "qty_to_buy" ? "text-purple" : ""
          }`,
          headerClassName: `text-center ${
            dataField === "qty_to_buy" ? "text-purple" : ""
          }`,
        })),
    ];
    setTableColumns(newTableColumns);
  }

  const generateTableData = (resultData) => {
    generateTableColumns(cols)
    tableDataClone.current = resultData;
    setCurrentItems(() => [...resultData]);
  };

  const handleSelect = (val) => {
    let { orderBy } = cloneDeep(fd);
    if (isRestaurantLoaded && !hasRetaurants) {
      if (val === "product") {
        // Toggle sort order
        orderBy = orderBy === "asc" ? "desc" : "asc";
        // Sort currentItems by name
        const sortedItems = currentItems.slice().sort((a, b) => {
          const aName = a.name ? a.name.toLowerCase() : "";
          const bName = b.name ? b.name.toLowerCase() : "";
          if (orderBy === "asc") {
            return aName.localeCompare(bName);
          } else {
            return bName.localeCompare(aName);
          }
        });
        setCurrentItems(sortedItems);
        setfd({ ...fd, sortBy: val, orderBy });
      } else if(val === "theoretical_stock"){
        orderBy = orderBy === "asc" ? "desc" : "asc";
        const sortedItems = currentItems.slice().sort((a, b) => {
          const aTotal = a.theoretical_stock ? a.theoretical_stock : 0;
          const bTotal = b.theoretical_stock ? b.theoretical_stock : 0;
          return orderBy === "asc" ? aTotal - bTotal : bTotal - aTotal;
        });
        setCurrentItems(sortedItems);
        setfd({ ...fd, sortBy: val, orderBy })
      } else if(val === "stock.unit_stock"){
        orderBy = orderBy === "asc" ? "desc" : "asc";
        const sortedItems = currentItems.slice().sort((a, b) => {
          const aTotal = a.stock?.unit_stock ? a.stock?.unit_stock : 0;
          const bTotal = b.stock?.unit_stock ? b.stock?.unit_stock : 0;
          return orderBy === "asc" ? aTotal - bTotal : bTotal - aTotal;
        });
        setCurrentItems(sortedItems);
        setfd({ ...fd, sortBy: val, orderBy })
      }
    }
    // Implement sorting on the frontend for cases where sorting isn't set up in the backend
    if (val === "in_unit_total" || val === "out_unit_total") {
      orderBy = orderBy === "asc" ? "desc" : "asc";
      const sortedItems = currentItems.slice().sort((a, b) => {
        const aTotal = a.stock_log ? a.stock_log[val] : 0;
        const bTotal = b.stock_log ? b.stock_log[val] : 0;
        return orderBy === "asc" ? aTotal - bTotal : bTotal - aTotal;
      });
      setCurrentItems(sortedItems);
      setfd({ ...fd, sortBy: val, orderBy })
    } else if (val === "loss") {
      orderBy = orderBy === "asc" ? "desc" : "asc";
      const sortedItems = currentItems.slice().sort((a, b) => {
        const aTotal = a.stock_log ? a.stock_log.in_unit_total - a.stock_log.out_unit_total : 0;
        const bTotal = b.stock_log ? b.stock_log.in_unit_total - b.stock_log.out_unit_total : 0;
        return orderBy === "asc" ? aTotal - bTotal : bTotal - aTotal;
      });
      setCurrentItems(sortedItems);
      setfd({ ...fd, sortBy: val, orderBy })
    } else if (fd.sortBy === val) {
      orderBy = orderBy === "asc" ? "desc" : "asc";
    }
    if (val === "product") {
      setfd({ ...fd, sortBy: val, orderBy });
    }
    true && setfd({ ...fd, sortBy: val, orderBy });
  };

  const selectChange = (it) => ({ target: { checked } }) => {
      if (checked) {
        dispatch({
          type: ACTION_TYPES.ADD_SELECTED_ITEM,
          items: [it],
        });
        return;
      }
      dispatch({
        type: ACTION_TYPES.REMOVE_SELECTED_ITEMS,
        items: [it.id],
      });
    };

  const selectAllProducts = ({ target: { checked } }) => {
    if (checked) {
      dispatch({
        type: ACTION_TYPES.ADD_SELECTED_ITEM,
        items: currentItems,
      });
      return;
    }
    dispatch({
      type: ACTION_TYPES.REMOVE_SELECTED_ITEMS,
      items: currentItems.map((t) => t.id),
    });
  };

  const tableInputChange = (it) => ({ target: { name, value } }) => {
      setStockValue(prev => {
        value = value === "" ? 0 : parseInt(value);
        const found = prev?.findIndex(item => item.id === it.id)
        if(found !== -1) {
          prev[found].stock.unit_stock += parseInt(value) - it.stock.unit_stock;
          return prev
        } else {
          prev.push({...it, stock: {...it.stock, unit_stock: parseInt(value) - it.stock.unit_stock}});
          return prev
      }
      })
      let newTableData = cloneDeep(currentItems);
      const isExist = newTableData.find((f) => f.id === it.id);
      if (isExist) {
        set(isExist, name, value);
        if(name === 'stock.unit_stock' || name === 'inventory'){
          set(isExist, "stock_gap" , (value - isExist.theoretical_stock));
        }
        isExist.state = "update";
        tableDataClone.current = newTableData
        newTableData = newTableData.map(item => {
          if(item.id == it.id) {
            return {
              ...item,
              stock: {
                ...item.stock,
              }
            }
          }
          return item
        })
        setCurrentItems(newTableData);
        dispatch({
          type: ACTION_TYPES.UPDATE_SELECTED_ITEM_DATA,
          payload: { id: it.id, name, value },
        });
      }
    };

    const onUpdateDelieveryStatus = async (purchase_statuses, statusFor) => {
      try {
        setLoading(true);
        const payload = {
          status_for: statusFor,
          purchase_statuses: purchase_statuses,
        };
        const result = await request.patch(
          `/purchase-orders/update-delivery-status`,
          payload
        );
        const data = await result.clone().json();
        if (result.ok) {
          if (currentPage < 1) {
            fetchAndSetOrder();
          } else {
            handlePageClick({ selected: currentPage });
          }
          setModalShow(false);
        } else {
          const errorMsg = (data && data.msg) || result.status;
          setError(`${errorMsg}`);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

  const onRowSelectChanged = (col, rowData) => (ev) => {
    if (col.dataField === "stock.expiry") {
      const result = col.options?.find((o) => o.id === +ev);
      if (!result) {
        return;
      }
      const newFilteredData = cloneDeep(currentItems);
      const isExist = newFilteredData.find((f) => f.id === rowData.id);
      if (isExist) {
        set(isExist, col.dataField, result.value);
        isExist.state = "update";
        tableDataClone.current = newFilteredData;
        setCurrentItems(newFilteredData);
        dispatch({
          type: ACTION_TYPES.UPDATE_SELECTED_ITEM_DATA,
          payload: {
            id: rowData.id,
            name: col.dataField,
            value: result.value,
          },
        });
      }
    }
  };

  useEffect(() => {
    if (stockEvolutionData) {
      let selectStock = processedCurrentItems.find(
        (i) => i.id == stockEvolutionData?.ingredient_id
      );
      setIsStockLevelDown(
        stockEvolutionData?.evolution?.some((i) => {
          if (i.stock < selectStock?.min_stock || i.stock <= 0) {
            return true;
          }
        })
      );
    }
  });

  
  const handleDoubleClick = (index, isOpen) => {
    if (isOpen) {
      setSleaveState((p) => ({ ...p, isOpen: false }));
      setTimeout(() => {
        setSleaveState((p) => ({ ...p, index: -1 }));
      }, 300);
    } else {
      setSleaveState((p) => ({ ...p, index, isOpen: true }));
    }
  };

  const onModalHide = () => {
    setModalShow(false);
    setIsModal(false);
    setSelectedIngredient(null)
  };

  const onUpdateRow = (orderData) => {
    setSelectedOrder(orderData);
    setModalShow(true);
  };


  return (
    <div className="leftcontent leftcontent-stock">
      {modalShow && <CustomModal
        show={modalShow}
        onHide={onModalHide}
        data={{ ...state, ...(selectedIngredient && { selectedIngredient }) }}
        className=""
        currentItems={currentItems}
        setCurrentItems={setCurrentItems}
      />}
      {/* <UploadModal
        show={isModal}
        onHide={() => setIsModal(false)}
        title="Stock updated !"
        subTitle="Your stock has been successfully updated."
      /> */}
      <div style={{ backgroundColor: "transparent" }} className="card m-10 mb-0">
        {/* <div className="card-header d-flex align-items-center justify-content-between">
          <h2>{t("StockDetails")}</h2>
          <div className="d-flex">
            {Boolean(state.selectedItems.length) && (
              <img
                src={Trashicon}
                onClick={deleteItems}
                className="me-3 trash-icon cursor-pointer"
                alt="..."
              />
            )}

            {Boolean(providersArr.length) && (
              <Dropdown onSelect={(val) => setActiveProvider(val)}>
                <Dropdown.Toggle
                  variant="link"
                  className="btn btn-white dropdown-toggle btn-icon me-3"
                >
                  {providersMap[activeProvider]}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {providersArr.map((d, i) => (
                    <Dropdown.Item key={i} eventKey={d.id}>
                      {d.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}

            <Dropdown onSelect={handleSelect}>
              <Dropdown.Toggle
                variant="link"
                className="btn btn-white dropdown-toggle btn-icon"
              >
                {fd.orderBy !== "asc" && (
                  <img
                    src={ArrowDownIcon}
                    alt=""
                    className="me-0"
                    style={{ width: "11px", paddingTop: "3px" }}
                  />
                )}
                {fd.orderBy === "asc" && (
                  <img
                    src={ArrowUpIcon}
                    alt=""
                    className="me-0"
                    style={{ width: "11px" }}
                  />
                )}
                <img
                  src={SortIcon}
                  alt=""
                  style={{
                    ...(fd.orderBy === "asc" && {
                      transform: "rotate(180deg)",
                    }),
                  }}
                />
                {fd?.sortBy === "loss" && true
                ? t("Loss")
                : cols.find((c) => c.dataField === fd.sortBy)?.dataField === "total_price"
                ? t("ProductPrice")
                : cols.find((c) => c.dataField === fd.sortBy)?.label
                }
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {cols
                  .filter(
                    (c) =>
                    (c.active === true || c.dataField === "product") &&
                      ![
                        "expiry",
                        "unit",
                        "qty_to_buy",
                        "product_quantity",
                        "total_price",
                        "stock_gap"
                      ].includes(c.dataField)
                  )
                  .map((d, i) => (
                    <Dropdown.Item
                      key={i}
                      eventKey={
                        d.dataField === "product_price"
                          ? "total_price"
                          : d.dataField
                      }
                    >
                      {d.label}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div> */}

        {stocksLoading && (
          <div className="w-100 d-flex justify-content-center card-spinner-container">
            <Spinner animation="border" variant="primary" />
          </div>
        )}

        {!stocksLoading &&
          <div className="card-body">
            <div className="gcontainer">
              <CustomTable
                columns={tableColumns}
                data={filteredTableDataWithTotal}
                selectChange={selectChange}
                selectedProducts={state.selectedItems.map((it) => it.id)}
                selectAllProducts={selectAllProducts}
                onInputChange={tableInputChange}
                onRowSelectChanged={onRowSelectChanged}
                selectedKey="id"
                onRowDoubleClick={handleDoubleClick}
                stockEvolutionData={stockEvolutionData}
                isStocksLevelDown={isStocksLevelDown}
                tableName="production-planning"
                pageName="labo"
                onUpdateRow={onUpdateRow}
                SleaveContent={StockEvolution}
              />

              <Row>
                <div className="d-flex justify-content-end mt-3">
                  <ReactPaginate
                    nextLabel={`${t("Next")}   >`}
                    onPageChange={handlePageClick}
                    forcePage={currentPage}
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={2}
                    pageCount={pageCount}
                    previousLabel={`<   ${t("Back")}`}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                    renderOnZeroPageCount={null}
                  />
                </div>

                {Boolean(currentItems.length) && (
                  <div className="d-flex justify-content-end mt-3 selected-prod-div">
                    {Boolean(state.selectedItems?.length) && (
                      <div className="d-flex flex-column me-3">
                        <label className="d-flex  justify-content-end">
                          {state.selectedItems?.length}
                        </label>
                        <label>{t("products selected")}</label>
                      </div>
                    )}
                  </div>
                )}
              </Row>
            </div>
          </div>
        }
      </div>
    </div>
  );
}

export default LeftSide;
