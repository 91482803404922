import { useState, useEffect, useReducer, useRef } from "react";
import { useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { set } from "lodash";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import * as XLSX from "xlsx";

import CustomTable from "components/customTable";
import SortFilter from "components/customTable/sortFilter";
import { useLoading } from "contexts/LoadingContextManagement";
import { DEFAULT_ERROR_MESSAGE } from "common/constants";
import { useUserData } from "contexts/AuthContextManagement";
import { cloneDeep } from "common/utils.ts";
import EyeIcon from "assets/images/icon/eye.svg";
import CheckIconInactive from "assets/images/icon/check-icon-inactive.svg";
import infoIcon from "assets/images/icon/info-icon.svg";
import CrossIconInactive from "assets/images/icon/cross-icon-inactive.svg";
import CheckIconActive from "assets/images/icon/check-icon-active.svg";
import CrossIconActive from "assets/images/icon/cross-icon-active.svg";
import reducer, { initialState } from "../../stock/reducer";

import providersData from "../../../data/providers.json"
import Trashicon from "assets/images/icon/filter.svg";
import TelechargerIcon from "assets/images/telecharger.png";
import orderData from "../../../data/orders.json"
import CustomModal from "./orderModel";
import FilterIcon from "assets/images/icon/FILTER_ICON1.svg";
import "../index.scss";
import OrderSentModal from "./orderSentModal";
import OrderSaveModal from "./orderSaveModal";

let isMountLoaded = false;

function capitalizeAndRemoveDashes(input) {
  return input ? input
    .split('-') // Split the string into words based on dashes
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter of each word
    .join(' ') : null // Join the words without spaces
}


function LeftSide({formData, clear}) {
  const { t } = useTranslation();
  const { setLoading, setError } = useLoading();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [fd, setfd] = useState({ orderBy: "desc", search: "" });
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [orderDetailsModalShow, setOrderDetailsModalShow] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState([]);
  const [providers, setProviders] = useState([]);
  const [changedOrders, setChangedOrders] = useState([]);
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [changedRows, setChangedRows] = useState([]);
  const tableRef = useRef(null);

  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  const [originalItems, setOriginalItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [showSentOrderModal, setShowSentOrderModal] = useState(false);
  const [showSaveOrderModal, setShowSaveOrderModal] = useState(false);
  const { selectedRestaurantId, isRestaurantLoaded, hasRetaurants, setFilterShown, isFilterShown } =
    useUserData();
  const history = useHistory();
  useEffect(() => {
    isMountLoaded = true;
  }, []);

  const tableColumns = [
    {
      dataField: "labo_name",
      caption: t("Labo"),
      className: "fw-bold",
      style: { width: "150px", fontWeight: "bold" },
      headerStyle: { width: "150px" },
      type: "string",
    },
    {
      dataField: "restaurant",
      caption: t("PointOfSale"),
      className: "fw-bold",
      style: { width: "150px", color: "#873CFC", fontWeight: "bold", textAlign: "center" },
      headerStyle: { width: "150px", textAlign: "center" },
      type: "string",
    },
    {
      dataField: "estimated_delivery_date",
      caption: t("ShippingDate"),
      className: "text-center",
      headerClassName: "text-center",
      type: "customRender",
      render: (col, it) => {
        return <span style={{ backgroundColor: "white", padding: "3px 5px", boxShadow: "0px 1px 3px 1px rgba(0,0,0,0.25)", borderRadius: "4px"}}>
          {it.estimated_delivery_date}
        </span>
      }
    },
    {
      dataField: "total_price",
      caption: t("TotalPrice"),
      className: "text-center",
      headerClassName: "text-center",
      style: { width: "100px", fontWeight: "bold" },
      headerStyle: { width: "100px" },
      type: "string",
    },
    {
      dataField: "action_status",
      caption: t("Action"),
      className: "text-center",
      headerClassName: "text-center",
      style: { width: "70px" },
      headerStyle: { width: "70px" },
      type: "customRender",
      render: (col, it) => {
        return <span style={{display: "flex", alignItems: "center", justifyContent: "space-around"}}>
          <img onClick={() => {
            setCurrentItems(currentItems.map((i) => {
              if (i.id === it.id) {
                return { ...i, delievry_status: i.delievry_status === "active" ? null : i.delievry_status === null ? "active" : i.delievry_status === 'in-active' ? "active" : "in-active", order_status: i.order_status === "cancelled" ? "in-preparation" : i.order_status === "sent" ? null : i.order_status === "in-preparation" ? null :  "in-preparation" };
              }
              return i;
            }))
          }} style={{cursor: "pointer"}} src={it.delievry_status === "active" ? CheckIconActive : CheckIconInactive} alt="check-icon"  />
          <img onClick={() => {
            setCurrentItems(currentItems.map((i) => {
              if (i.id === it.id) {
                return { ...i, delievry_status: i.delievry_status === "active" ? "in-active" : i.delievry_status === "in-active" ? null : "in-active", order_status: (i.order_status === "sent" || i.order_status === "in-preparation" || i.order_status === null ) ? "cancelled" : null };
              }
              return i;
            }))
          }}  style={{cursor: "pointer"}} src={(it.delievry_status === "active" || it.delievry_status === null) ? CrossIconInactive : CrossIconActive} alt="cross-icon" />
          {/* <img src={CrossIconActive} alt="cross-icon" /> */}
        </span>
      }
    },
    {
      dataField: "delievry_status",
      caption: t("OrderStatus"),
      className: "text-center",
      headerClassName: "text-center",
      type: "customRender",
      render: (col, it) => {
        let status = it?.order_status;
        return it?.order_status ? (<div style={{background: status == 'sent' ? "#E4FFC1" : status == 'in-preparation' ? "#FFF7DE" : "#FFC4C4", padding: "3px 5px", width: "200px", borderRadius: "19px", color:  status == 'sent' ? "#A8EA53" : status == 'in-preparation' ? "#F2B705" : "#EA5353", margin: "auto", border: `1px solid ${status == 'sent' ? "#A8EA53" : status == 'in-preparation' ? "#F2B705" : "#EA5353"}`}}>
          {t(capitalizeAndRemoveDashes(it?.order_status))}
        </div>) : <span></span>
      }
    },
    {
      dataField: "action",
      caption: "",
      className: "text-center",
      headerClassName: "text-center custome-width",
      headerStyle: { width: "100px" },
      active : true,
      type: "customRender",
      render: (col, it) => (
        <button onClick={showRow(it)} className="order-edit-btn">{t("EDIT")}</button>
        // <img
        //   src={EyeIcon}
        //   className="cursor-pointer"
        //   alt="..."
        //   onClick={showRow(it)}
        // />
      ),
    }
  ];

  const deleteItems = () => {
    let newTableData = currentItems?.filter(
      (f) => {
        if(!selectedRows.includes(f.id)) {
          setSelectedRows(selectedRows.filter(item => item === f.id))
          return true
        }
        return false
      }
    );
    setCurrentItems(newTableData)
  };

  useEffect(() => {
    if (isRestaurantLoaded && !hasRetaurants) { 
      if(formData?.products?.length) {
        setCurrentItems(orderData?.purchase_orders.filter(item => formData.products.includes(item.id)));
        return
      }

      if(formData.order_status?.length) {
        setCurrentItems(orderData?.purchase_orders.filter(item => formData.order_status.includes(item.order_status)));
        return
      }

      if(formData?.pos?.length) {
        setCurrentItems(orderData?.purchase_orders.filter(item => formData.pos.includes(item.restaurant)));
        return
      }

      // setCurrentItems(orderData?.purchase_orders.filter(item => {
      //   if(formData.start_date.includes(item.estimated_delivery_date.replace("/", "-")) && formData.end_date.includes(item.estimated_delivery_date.replace("/", "-"))) {
      //     return item
      //   }
      // }));
      setCurrentItems(orderData?.purchase_orders);
      setProviders(providersData);
      return;
    }
  }, [selectedRestaurantId, isRestaurantLoaded, hasRetaurants, formData]);

  // useEffect(() => {
  //   if (!isMountLoaded || selectedRestaurantId === "") {
  //     let updatedItems = [...originalItems];
  //     // Filter by provider
  //     if (selectedProvider) {
  //       const providerName = selectedProvider.name.toLowerCase();
  //       updatedItems = updatedItems.filter(
  //         (item) => item.provider.name.toLowerCase() === providerName
  //       );
  //     }
  
  //     // Filter by search term
  //     if (fd.search) {
  //       updatedItems = updatedItems.filter((item) =>
  //         item.order_number.toLowerCase().includes(fd.search.toLowerCase())
  //       );
  //     }

  //     // Sort by specified field
  //     if (fd.sortby) {
  //       updatedItems.sort((a, b) => {
  //         const aValue = a[fd.sortby]?.toString().toLowerCase();
  //         const bValue = b[fd.sortby]?.toString().toLowerCase();
  
  //         if (aValue < bValue) return -1;
  //         if (aValue > bValue) return 1;
  //         return 0;
  //       });
  //     }
  //     setCurrentItems(updatedItems);
  //     return;
  //   }
  // }, [
  //   fd.search,
  //   fd.sortby,
  //   fd.orderBy,
  //   selectedRestaurantId,
  //   selectedProvider,
  //   originalItems
  // ]);

  const showRow = (row) => () => {
    setSelectedIngredient(row);
    setModalShow(true);
  };

  const onRowSelectChanged = (col, rowData) => (ev) => {
    const result = col.options.find((o) => o.id === ev);
    if (!result) {
      return;
    }
    const newFilteredData = cloneDeep(currentItems);
    const isExist = newFilteredData.find((f) => f.id === rowData.id);
    if (isExist) {
      set(isExist, col.dataField, result.value);
      isExist.state = "update";
      setCurrentItems(newFilteredData);
    }
  };

  const onSearchChange = (ev) => {
    if(ev.target.value) {
      setfd({ ...fd, search: ev.target.value });
      const updatedCurrentItems = orderData?.purchase_orders.filter((i) => i.labo_name.toLowerCase().includes(ev.target.value.toLowerCase()))
      setCurrentItems([...updatedCurrentItems])
    }else {
      setCurrentItems(orderData?.purchase_orders)
    }
  };

  let getSortedItems = (val) => {
    if (val === 'estimated_delivery_date') {
      setCurrentItems(currentItems.sort((a, b) => {
        // Convert dob strings to Date objects
        let dateA = new Date(a[val]?.split('/').reverse().join('-'));
        let dateB = new Date(b[val]?.split('/').reverse().join('-'));
        return dateA - dateB; // Sort in ascending order
      }));
      return
    }
    setCurrentItems(currentItems.sort((a, b) => a[val].localeCompare(b[val])));
    
  };

  const selectAllRows = ({ target: { checked } }) => {
    setSelectedRows(() => (checked ? [...currentItems.map((f) => f.id)] : []));
  };

  const handleExport = () => {
    if (tableRef.current) {
      // Get the table DOM element
      const table = tableRef.current;

      // Convert the table to a worksheet
      const worksheet = XLSX.utils.table_to_sheet(table);

      // Create a workbook and append the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // Write the workbook and trigger the download
      XLSX.writeFile(workbook, "TableData.xlsx");
    }
  };

  const selectChange = (it) => ({ target: { checked } }) => {
      const newSelectedProducts = JSON.parse(JSON.stringify(selectedRows));
      if (checked) {
        setSelectedRows(() => [...newSelectedProducts, it.id]);
      } else {
        setSelectedRows(() => newSelectedProducts.filter((p) => p !== it.id));
      }
    };

  // Invoke when user click to request another page.
  const handlePageClick = async (event) => {
    try {
    //   const result = await getOrders(event.selected + 1);
    //   result.purchase_orders.forEach((value) => {
    //     value.delievry_status = value.is_delivered
    //       ? "Delivered"
    //       : "Not Delivered";
    //   });
    //   setCurrentItems(() => result.purchase_orders);
    //   setPageCount(result.total_pages);
    //   setCurrentPage(event.selected);
    } catch (error) {
      setError(DEFAULT_ERROR_MESSAGE);
    }
  };

  const onUpdateRow = (orderData) => {
    setSelectedOrder(orderData);
    setOrderDetailsModalShow(true);
  };

  const onReportRow = (orderData) => {
    setSelectedOrder(orderData);
  };

  const onModalHide = () => {
    setModalShow(false);
    setSelectedIngredient(null)
    // setShowSentOrderModal(false)
  };

  return (
    <div className="my-orders">
      {modalShow && <CustomModal
        show={modalShow}
        onHide={onModalHide}
        data={{ ...state, ...(selectedIngredient && { selectedIngredient }) }}
        className=""
        setShowSentOrderModal={setShowSentOrderModal}
        setShowSaveOrderModal={setShowSaveOrderModal}
        currentItems={currentItems}
        setCurrentItems={setCurrentItems}
      />}
       {showSentOrderModal && <OrderSentModal
        show={showSentOrderModal}
        onHide={() => setShowSentOrderModal(false)}
        data={{ ...state, ...(selectedIngredient && { selectedIngredient }) }}
        className=""
        setShowSentOrderModal={setShowSentOrderModal}
      />}
      {showSaveOrderModal && <OrderSaveModal
        show={showSaveOrderModal}
        onHide={() => setShowSaveOrderModal(false)}
        data={{ ...state, ...(selectedIngredient && { selectedIngredient }) }}
        className=""
        setShowSentOrderModal={setShowSaveOrderModal}
      />}
      <Row>
        <Col className="d-flex justify-content-start">
          { selectedRows.length === 0 ? (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip-info">
                <span>
                  <img
                    src={infoIcon} // Replace with your info icon URL or component
                    alt="info"
                    style={{ width: "16px", marginRight: "5px" }}
                  />
                  {t("Please select points of sale")}
                </span>
              </Tooltip>
            }
          >
            <Button
              disabled={!selectedRows.length}
              className="btn btn-primary add-btn-container"
            >
              {t("Send Selected Orders")}
            </Button>
          </OverlayTrigger>
        ) : 
          <Button disabled={!selectedRows.length} className="btn btn-primary add-btn-container">
            {t("Send Selected Orders")}
          </Button>
          }
          <Button
            className="btn btn-primary add-btn-container"
          >
            {t("Consolidated delivery note")}
          </Button>
        </Col>
        <Col className="d-flex justify-content-end">
        {Boolean(selectedRows.length) && (
              <img
                src={Trashicon}
                onClick={deleteItems}
                className="me-3 trash-icon cursor-pointer"
                alt="..."
              />
            )}
          <input
            type="search"
            className="search-input me-3"
            placeholder={t("Search")}
            onChange={onSearchChange}
          />
           <button  className="btn btn-white btn-icon me-1" style={{ padding: "2px 13px", height: "38px"}}>
            <img  onClick={handleExport} src={TelechargerIcon} alt="" className="m-0" />
          </button>
          <SortFilter
            cols={tableColumns.slice(0, 3)}
            fd={fd}
            setFd={setfd}
            getSortedItems={getSortedItems}
            rootClassName="sort-filter ps-3"
            
          />
          {!isFilterShown && (
              <div className="headerbtns ms-3">
                <button
                  onClick={() => setFilterShown(true)}
                  className="btn btn-white btn-icon btn-theme"
                  style={{
                    height:"35px"
                  }}
                >
                  <img src={FilterIcon} alt="" className="m-0" />
                </button>
              </div>
            )}
        </Col>

      </Row>

      <Row className="mt-3 table-container">
        <CustomTable
          columns={tableColumns}
          data={currentItems}
          ref={tableRef}
          tableName="labo-order"
          pageName="labo"
          selectChange={selectChange}
          selectedProducts={selectedRows}
          selectAllProducts={selectAllRows}
          onRowSelectChanged={onRowSelectChanged}
          onUpdateRow={onUpdateRow}
          onReportRow={onReportRow}
        />

        <Row>
          <div className="d-flex justify-content-end mt-2">
            <ReactPaginate
              nextLabel={`${t("Next")}   >`}
              onPageChange={handlePageClick}
              forcePage={currentPage}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel={`<   ${t("Back")}`}
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName="active"
              renderOnZeroPageCount={null}
            />
          </div>
        </Row>
      </Row>
    </div>
  );
}

export default LeftSide;
