import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Order from "./order";
import ProductionPlanning from "./productionPlanning";

import "./index.scss";
import SalesForecast from "./salesForecast";
import Analytics from "./analytics";

function Labo() {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState('order');

  return (
    <>
      <div className="divider">
        <ul className="navbtns mb-0">
          <li className={activeTab === 'order' ? "active" : ""}>
              <button
                className={`nav-link ${activeTab === 'order' ? "active" : ""}`}
                onClick={() => setActiveTab('order')}
              >
                {t("Orders")}
              </button>
           </li>
           <li className={activeTab === 'productionPlanning' ? "active" : ""}>
            <button
              className={`nav-link ${activeTab === 'productionPlanning' ? "active" : ""}`}
              onClick={() => setActiveTab('productionPlanning')}
            >
              {t("ProductionPlanning")}
            </button>
          </li>
          <li className={activeTab === 'salesForecast' ? "active" : ""}>
            <button
              className={`nav-link ${activeTab === 'salesForecast' ? "active" : ""}`}
              onClick={() => setActiveTab('salesForecast')}
            >
              {t("SalesForecast")}
            </button>
          </li>
          <li className={activeTab === 'analytics' ? "active" : ""}>
            <button
                className={`nav-link ${activeTab === 'analytics' ? "active" : ""}`}
                onClick={() => setActiveTab('analytics')}
            >
              {t("Analytics")}
            </button>
          </li>
        </ul>
      </div>
      <div className="after-divider-container">
        {activeTab === 'order' && <Order />}
        {activeTab === 'productionPlanning' && <ProductionPlanning />}
        {activeTab === 'salesForecast' && <SalesForecast />}
        {activeTab === 'analytics' && <Analytics />}
      </div>
    </>
  );
}

export default Labo;