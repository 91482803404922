import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal, Spinner, Row, Col } from "react-bootstrap";

import TopAndFlopView from "views/commonViews/topAndFlop";
import BasicLineChart from "components/basicLineChart2";
import CustomTable from "components/customTable";
import { useUserData } from "contexts/AuthContextManagement";
import {yearOccupancyData, monthOccupancyData, weakOccupancyData} from "./utils"
function Comparison({
  formData,
  lineChartData,
  getMealGraphData,
  mealsLoading
}) {
  const { t } = useTranslation();
  const [comparisionData, setComparisionData] = useState([]);
  const {
    isRestaurantLoaded,
    hasRetaurants
  } = useUserData();

  const seriesLineChart = useMemo(() => {
    return lineChartData.map((d) => ({
      ...d,
      events: {
        legendItemClick: (ev) =>
          !ev.target.visible ? getSameOrderMeals({ ev, col: d }) : undefined,
      },
    }));
  }, [lineChartData]);

  const getSameOrderMeals = async (ev) => {
    // const result = await request.get(
    //   "tables/meals/same-ordered-meals",
    //   omit({ ...payload, meal_id: ev?.col?.id }, [
    //     "start_time",
    //     "end_time",
    //     "meals",
    //   ])
    // );
    // setIsTooltipModal({ ...ev, result });
  };

  useEffect(()=>{
    if (formData.month) {
      setComparisionData(monthOccupancyData)
    } else if (formData.week) {
      setComparisionData(weakOccupancyData)
    } else {
      setComparisionData(yearOccupancyData)
    }

  }, [formData])

   return (
    <>
      <div className="col-lg-12 h-100">
        <div className="card mb-0 h-100">
          <div
            className="card-header d-flex flex-column align-items-start"
            style={{
              borderBottom: "2px solid #E0E0E0",
              paddingBottom: "6px",
              position: "relative"
            }}
          >
            <h2 style={{ color: "#6353ea", fontSize: "21px" }}>{t("Chart")}</h2>
            <div
              style={{
                backgroundColor: "#6353ea",
                height: "2px",
                width: "100px",
                position: "absolute",
                bottom: "-2px", 
                left: "2px"
              }}
            ></div>
          </div>

          <div className="card-body h-100">
            {mealsLoading && (
              <div className="w-100 d-flex justify-content-center card-spinner-container">
                <Spinner animation="border" variant="primary" />
              </div>
            )}
            {!mealsLoading &&
             <BasicLineChart 
               formData={formData} 
               getMealGraphData={getMealGraphData} 
               series={seriesLineChart} 
               data={comparisionData} 
            />}
          </div>
        </div>
      </div>
    </>
  );
}

export default Comparison;