import React, { useState, useEffect } from "react";
import convertUnits from 'convert-units';

import {
  Modal,
  Button,
  Row,
  Col,
  Container,
  Card,
  Badge,
  Form,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useLoading } from "contexts/LoadingContextManagement";
import { useUserData } from "contexts/AuthContextManagement";
import request from "services/request";

import UploadedIcon from "assets/images/uploaded_meal.png";
import QuantityContainer from "components/quantityBtnContainer";

import "./index.scss"

function Text({caption, style}) {
  let customStyle = {...style, border: 'none', width: "auto"}
  return (
    <>
      <span style={customStyle} className="add-restaurants-input-title form-control bg-transparent">{caption}</span>
    </>
  );
}

let ORDERS = {
  "b53bc325-e967-4674-92aa-ee87f269a37d": [
    {
      reference: "21783718",
      name: "Éclair café",
      condition: "Carton",
      unit_cost: 1.9,
      margin: 20,
      unit_selling_price: 2.3,
      quantity: 50,
      total_selling_price: 115,
    },
    {
      reference: "21783723",
      name: "Tarte au daim",
      condition: "Carton",
      unit_cost: 1.4,
      margin: 10,
      unit_selling_price: 1.55,
      quantity: 15,
      total_selling_price: 23.25,
    }
  ],
  "75c0efc8-3bc6-47f4-8591-bede37c1a981": [
    {
      reference: "21783718",
      name: "Éclair café",
      condition: "Carton",
      unit_cost: 1.9,
      margin: 20,
      unit_selling_price: 2.3,
      quantity: 18,
      total_selling_price: 41.4,
    },
    {
      reference: "21783723",
      name: "Tarte au daim",
      condition: "Carton",
      unit_cost: 1.4,
      margin: 10,
      unit_selling_price: 1.55,
      quantity: 5,
      total_selling_price: 7.75,
    }
  ],
  "1989c80a-7b10-4453-8f50-7d54fb07f66a": [
    {
      reference: "21783718",
      name: "Éclair café",
      condition: "Carton",
      unit_cost: 1.9,
      margin: 20,
      unit_selling_price: 2.3,
      quantity: 5,
      total_selling_price: 11.5,
    },
    {
      reference: "21783723",
      name: "Tarte au daim",
      condition: "Carton",
      unit_cost: 1.4,
      margin: 10,
      unit_selling_price: 1.55,
      quantity: 14,
      total_selling_price: 21.27,
    }
  ],
  "d3f069cd-71bf-425c-8649-39f07aaff17f": [
    {
      reference: "21783718",
      name: "Éclair café",
      condition: "Carton",
      unit_cost: 1.9,
      margin: 20,
      unit_selling_price: 2.3,
      quantity: 20,
      total_selling_price: 46,
    },
    {
      reference: "21783723",
      name: "Tarte au daim",
      condition: "Carton",
      unit_cost: 1.4,
      margin: 10,
      unit_selling_price: 1.55,
      quantity: 35,
      total_selling_price: 54.25,
    }
  ]
}

function CustomModal({
  onHide,
  show,
  data: { selectedIngredient },
  isUploadedModal = true,
  setShowSentOrderModal,
  setShowSaveOrderModal,
  setCurrentItems,
  currentItems
}) {
  const { t } = useTranslation();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isUploaded, setIsUploaded] = useState(false);
  const { setLoading, setError } = useLoading();
  const [files, setFiles] = useState([]);
  const [fd, setFd] = useState({ allergens: [], details: [] });
  const [quantity, setQuantity] = useState(selectedIngredient?.stock?.unit_stock);

  const {
    selectedRestaurantId,
    isRestaurantLoaded,
    hasRetaurants,
    setSampleRestaurantModal,
  } = useUserData();

  useEffect(() => {
    setFd({ allergens: [], details: [] });
    setFiles([]);
    setIsSubmitDisabled(true);
    setIsUploaded(false);
  }, [show]);

  useEffect(() => {
    if (
      fd?.name &&
      fd?.cost_excl_tax &&
      fd?.category &&
      fd?.unit
    ) {
      setIsSubmitDisabled(false);
      return;
    }
    setIsSubmitDisabled(true);
  }, [fd]);

  useEffect(() => {
    if (selectedIngredient) {
      setFd({
        ...selectedIngredient,
        cost_excl_tax: (selectedIngredient.cost_excl_tax / selectedIngredient.details?.find(p => p.is_default === true)?.provider_ingredient?.recipe_unit_quantity)?.toFixed(4),
        allergens: selectedIngredient?.allergens?.map((a) => a.id),
      });
    }
  }, [selectedIngredient]);

  const sendOrderHandler = () => {
    let updatedCurrentItems = currentItems.map(item => {
      if(item.id === selectedIngredient.id) {
        return {...item, order_status: "sent", delievry_status: "active"}
      }
      return item
    })
    setCurrentItems(updatedCurrentItems)
    setShowSentOrderModal(true);
    onHide();
  }

  const setCurrentValue = (value) => {
    setQuantity(value);
    setFd({ ...fd, stock: { unit_stock: value } });
  };

  const onSubmit = async (ev) => {
    ev.preventDefault();
    try {
      if (isRestaurantLoaded && !hasRetaurants) {
        setSampleRestaurantModal(true);
        onHide();
        return;
      }
      setIsSubmitDisabled(true);
      setLoading(true);
      const payload = {
        restaurant_id: selectedRestaurantId,
        ...fd,
        cost_excl_tax: fd.cost_excl_tax * fd.details?.find(p => p.is_default)?.provider_ingredient.recipe_unit_quantity,
        unit: fd?.converted_unit ?? fd?.unit,
        details: fd?.details.map(item => ({provider_ingredient_id: item.provider_ingredient.id, is_default: item.is_default }))
      };

      if(fd.details.length === 0) {
        setError(t("Please Select Provider ingredient to create ingredient"))
        return
      }

      if(!fd.details?.some((item => item.is_default))){
        setError("Please Select Default value")
        return
      }
      delete payload.converted_unit;

      if (!selectedIngredient) {
        const result = await request.post("/ingredients", payload);
        throw Error(result);
      }
      delete payload.id;
      delete payload.provider_name;
      delete payload.createdAt;
      delete payload.updatedAt;
      delete payload.label;
      delete payload.value;
      delete payload.deletedAt;
      if (payload.image_path !== "" && payload.image_path !== null) {
        delete payload.image_path;
      }
      const resultQuery = await request.patch(
        `/ingredients/${selectedIngredient.id}`,
        payload
      );
      throw Error(resultQuery);
    } catch (error) {
      console.log(error);
      setIsSubmitDisabled(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className="add-ingredient-labo"
      backdropClassName="add-ingredient-backdrop"
    >
      <Modal.Header className="add-restaurants-modal-header" closeButton>
        {!isUploaded && (
          <Modal.Title className="add-restaurants-modal-title">
            {t("Order number")} 14U24U2I48
          </Modal.Title>
        )}
      </Modal.Header>

      <Modal.Body>
        <Container className="p-0">
          {isUploadedModal && isUploaded && (
            <div className="d-flex justify-content-center flex-column  text-center upload-container">
              <div>
                <img src={UploadedIcon} alt="..." />
              </div>
              <div className="heading mt-5">
                <label>{t("Order uploaded")} !</label>
              </div>
              <div className="subheading mt-2 mb-5">
                <label>
                  {t("Your Order has been successfully uploaded")}.
                </label>
              </div>
              <div>
                <Button className="add-restaurant-confirm-btn" onClick={onHide}>
                  {t("OK")}
                </Button>
              </div>
            </div>
          )}
          {!isUploaded && (
            <Form noValidate className="ps-0 pe-0" onSubmit={onSubmit}>
              <Row className="mt-4">
                  <Col lg={12}>
                    <Card className="mb-0 bg-transparent">
                      <div className="p-4 allergens-container">

                        <div className="row custom-table h-100">
                          <div className="col-lg-12 h-100 p-0">
                            <div className="tablescroll">
                              <table className="table bg-transparent">
                                <thead>
                                  <tr>
                                    <td style={{ width: "25%" }}>
                                    {t("Reference")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "50%" }}
                                    >
                                      {t("ProductName")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "30%" }}
                                    >
                                      {t("Condition")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "25%" }}
                                    >
                                      {t("UnitCost")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "25%" }}
                                    >
                                      {t("Margin (%)")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "25%" }}
                                    >
                                      {t("Unit selling price")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "25%" }}
                                    >
                                      {t("Quantity")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "25%" }}
                                    >
                                      {t("Total sale price")}
                                    </td>
                                  </tr>
                                </thead>
                                <tbody>
                                    {ORDERS[selectedIngredient.id].map(ingredient => (
                                      <tr
                                      className="flex justify-between"
                                    >
                                      <td style={{ height: "50px" }}>
                                        <Text style={{width: "100%", display: "inline-block", whiteSpace: "initial"}}  caption={ingredient?.reference} />
                                      </td>
                                      <td className="text-center" style={{ width: "200px" }}>
                                        <Text style={{overflow: "hidden"}} caption={ingredient?.name} />
                                      </td>
                                      <td className="text-center" style={{ width: "100px" }}>
                                        <Text caption={ingredient?.condition} />
                                      </td>
                                      <td className="text-center" style={{ width: "100px" }}>
                                        <Text caption={ingredient?.unit_cost} />
                                      </td>
                                      <td className="text-center" style={{ width: "100px" }}>
                                        <Text caption={ingredient?.margin} />
                                      </td>
                                      <td className="text-center" style={{ width: "100px" }}>
                                        <Text caption={ingredient?.unit_selling_price} />
                                      </td>
                                      <td className="text-center" style={{ width: "100px" }}>
                                        <QuantityContainer currentValue={ingredient.quantity} setCurrentValue={setCurrentValue} />
                                      </td>
                                      <td className="text-center" style={{ width: "100px" }}>
                                        <Text caption={ingredient?.total_selling_price} />
                                      </td>
                                      <td style={{ width: "100px" }} className="text-center">
                                      </td>
                                    </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>

              <Modal.Footer className="labo-order-modal-footer">
                <Button
                  type="submit"
                  className="labo-order-modal-btn"
                  style={{ backgroundColor: "#873CFC"}}
                  onClick={() => {
                    setShowSaveOrderModal(true);
                    onHide();
                  }}
                >
                  {t("To safeguard")}
                </Button>
                <Button
                  type="submit"
                  className="labo-order-modal-btn"
                  style={{ backgroundColor: "#873CFC"}}
                  onClick={sendOrderHandler}
                  disabled={selectedIngredient.order_status === "sent"}
                >
                  {t("Send order")}
                </Button>
                <Button
                  type="submit"
                  className="labo-order-modal-btn"
                  onClick={() => {
                    setShowSentOrderModal(true);
                    onHide();
                  }}
                  style={{ backgroundColor: "#873CFC"}}
                >
                  {t("View the order form")}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default CustomModal;
