import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import { useTranslation } from "react-i18next";
import HighchartsReact from "highcharts-react-official";
import "./index.scss";

function BasicLineChart({ formData, data: lineChartData }) {
  const { t } = useTranslation();
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    // Transform data for the graph
    const categories = lineChartData.map((item) => item.date); // x-axis categories (dates)
    const actualOccupancy = lineChartData.map(
      (item) => item.occupancy[0]?.actual_occupancy || 0
    );
    const predictedOccupancy = lineChartData.map(
      (item) => item.occupancy[0]?.predicted_occupancy || 0
    );
    const comparisionOccupancy = lineChartData.map(
      (item) => item.occupancy[0]?.comparision_occupancy || 0
    );

    let comparisionName = t("Year-1"); 
    if (formData.month) {
      comparisionName = t("Month-1");
    } else if (formData.week) {
      comparisionName = t("Week-1");
    }

    // Update chart options
    setChartOptions({
      chart: {
        type: "spline",
        zoomType: "x",
      },
      title: {
        text: "",
      },
      xAxis: {
        categories, // Dates on the x-axis
        title: {
          text: "Date",
          style: {
            color: "#000000",
            fontSize: "16px",  
            fontWeight: "600", 
          },
        },
        labels: {
          formatter: function () {
            const date = new Date(this.value);
            const day = String(date.getDate()).padStart(2, '0'); // Get the day with leading zero
            const month = date.toLocaleString('default', { month: 'short' }); // Get the short month name
            return `${day} ${month}`;
          },
          style: {
            color: "#000000",
            fontSize: "16px",  
            fontWeight: "600", 
          },
        },
      },
      yAxis: {
        title: {
          text: "Clients",
          style: {
            color: "#000000",
            fontSize: "16px",  
            fontWeight: "600", 
          },
        },
        labels: {
          style: {
            color: "#000000",  // Same color as title
            fontSize: "16px",   // Same font size as title
            fontWeight: "600",  // Same font weight as title
          },
        },
        tickInterval: 100,
      },
      tooltip: {
        shared: false, 
        useHTML: true,
        borderRadius: 8, 
        borderWidth: 0,
        shadow: true,
        backgroundColor: null,
        style: {
          color: "#fff", 
          fontSize: "14px",
          fontWeight: "bold",
        },
        formatter: function () {
          let backgroundColor;
          // Dynamically change background color based on the series name
          if (this.series.name === "Actual") {
            backgroundColor = "#000000";  
          } else if (this.series.name === "Year-1") {
            backgroundColor = "#727478"; 
          } else if (this.series.name === "Forecast") {
            backgroundColor = "#6353ea";  
          }
      
          return `<div style="text-align: center; line-height: 1.4; padding: 10px 20px; background-color: ${backgroundColor}; border-radius: 8px;">
                    <span style="font-size: 18px;">${this.y}</span><br/>
                    <span>Clients</span>
                  </div>`;
        },
      },
      
      legend: {
        align: "center", 
        verticalAlign: "top", 
        layout: "horizontal",
        itemStyle: {
          fontWeight: "bold",
          fontSize: "14px",
          color: "#333",
        },
        symbolWidth: 30, 
        symbolHeight: 2,
        symbolRadius: 0,
        labelFormatter: function () {
          return `<span style="color: ${this.color}">${this.name}</span>`;
        },
      },
      series: [
        {
          name: t("Forecast"),
          data: predictedOccupancy,
          color: "#6353ea",
        },
        {
          name: t("Actual"),
          data: actualOccupancy,
          color: "#000000",
        },
        {
          name: comparisionName,
          data: comparisionOccupancy,
          color: "#727478", 
          dashStyle: "Dash",
        },
      ],
    });
  }, [lineChartData]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={chartOptions}
      containerProps={{
        className: "occupancy-graph",
        style: { height: "400px", width: "100%" },
      }}
    />
  );
}

export default BasicLineChart;