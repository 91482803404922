import React, { useState } from "react";
import moment from "moment";

import LeftSide from "./leftSide";
import "../index.scss";
import RightSide from "./rightSide";

function SalesForecast() {
  const [clear, setClear] = useState(false);
  const [formData, setformData] = useState({
    start_date: moment().format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD"),
    products: [],
  });


  const onApply = (params) => {
    setformData({ ...params });
  };

  return (
    <>
      <LeftSide formData={formData} clear={clear}/>
      <RightSide onApply={onApply} formData={formData} setClear={(clear) => setClear(clear)} />
    </>
  );
}

export default SalesForecast;
