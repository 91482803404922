import { useState, useEffect, useRef, useMemo } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import request from "services/request";
import LineChart from "components/lineChart";
import BarChart from "components/barChart";
import BasicLineChart from "components/basicLineChart2";
import CustomTable from "components/customTable";
import { DEFAULT_ERROR_MESSAGE } from "common/constants";
import { useUserData } from "contexts/AuthContextManagement";
import { useLoading } from "contexts/LoadingContextManagement";
import { getRandomNumber } from "./data";
import { getDummyFilteredData, timezoneFormat } from "common/utils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  parseData,
  generateAvgHourlyOccupancy,
  getGuests,
  sumDailyOccupancy,
  getDatesInRange,
  dummyYearlyData,
  dummyMonthlyData,
  dummyWeeklyData
} from "./utils";
import occupancyGuestsDummy from "../../data/occupancy_guests.json";
import Comparison from "./comparision";

function LeftYearlyOccupancy({ formData }) {
  const { t } = useTranslation();
  const { setLoading, setError } = useLoading();
  const {
    selectedRestaurantId,
    selectedRestaurant,
    isRestaurantLoaded,
    hasRetaurants,
  } = useUserData();
  const [lineChartData, setLineChartData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [dates, setDates] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const prevFromData = useRef(formData)
  const updateLanguage = localStorage.getItem('fullsoon_i18nextLng');

  const formatDate = (dateString) => {
    const date = moment(dateString);
    const formattedDate = date.format("DD/MM"); 
    return formattedDate;
  };
  
  const getDates = (startDate, endDate) => {
    const dates = [];
      let currentDate = moment(startDate); 
  
      while (currentDate <= moment(endDate)) {
          dates.push(currentDate.format('YYYY-MM-DD'));
          currentDate = currentDate.clone().add(1, 'day'); 
      }
      return dates;
  }

    const formatData = () => {
      const allDates = getDates(formData.start_date, formData.end_date);
      setDates(allDates);
      // return (days ?? []).map((i) => {
      //   return {
      //   ...i
      //   };
      // });
    };

    const getOccupancyColumns = (t, dates) => {
      const renderValueColumn = (column, item, dataIndex) => {
        const colorStyle =  { color: "#000000" };
        const value = item[dates[dataIndex]];
        const fontWeight = "500";
        const fontSize = "18px" ;

        if (item.name == "Forecast") {
          return (
            <div className="forecast-values" style={{ fontSize, fontWeight, height: "35px", width: "110px", color: "#6353ea", margin: "auto"}}>
              {`${value}`}
            </div>
          );
        }

        if (item.name == "Actual") {
          return (
            <div className="forecast-values" style={{ fontSize, fontWeight, ...colorStyle }}>
              {`${value}`}
            </div>
          );
        }

        if (item.name == "Year-1") {
          return (
            <div className="forecast-values" style={{ fontSize, fontWeight, ...colorStyle }}>
              {`${value}`}
            </div>
          );
        }
        if (item.name == "Month-1") {
          return (
            <div className="forecast-values" style={{ fontSize, fontWeight, ...colorStyle }}>
              {`${value}`}
            </div>
          );
        }
        if (item.name == "Week-1") {
          return (
            <div className="forecast-values" style={{ fontSize, fontWeight, ...colorStyle }}>
              {`${value}`}
            </div>
          );
        }
      };

      const columns = dates.map((date, index) => {
        const fontSize =  "18px";
        return {
          dataField: date,
          caption: (
              <span className="forecast-values" style={{fontSize, fontWeight: 500, color: "#8B8F94" }}>{formatDate(date, t)}</span>
          ),
          className: "fw-bold",
          style: { width: "150px", fontSize: "16px", fontWeight: "500 !important",},
          headerStyle: { width: "150px" },
          type: "customRender",
          custom: "events",
          render: (column, item) => renderValueColumn(column, item, index),
      }});

      columns.unshift({
        dataField: "name",
        caption: (
          <span style={{ fontSize: "16px", color: "#000000", fontWeight: "700" }}>{t("")}</span>
        ),
        className: "fw-bold",
        style: { width: "150px", fontSize: "16px", fontWeight: "700" },
        headerStyle: { width: "150px" },
        type: "customRender",
        render: (column, item) => (
          <span style={{ fontSize: "16px", color: "#000000", fontWeight: "700" }}>
            {t(item?.name)}
          </span>
        ),
      });

    return columns;
  };

  const cols = useMemo(() => getOccupancyColumns(t, dates), [t, formData, dates]);

  const generateTableColumns = (cols) => {
    const newTableColumns = [
      ...cols
        .map(({ caption, dataField, ...rest }) => ({
          ...rest,
          caption: caption,
          dataField,
        }))
    ];
    setTableColumns(newTableColumns);
  }

  useEffect(() => {
    formatData();
    generateTableColumns(cols);
  },[formData, isRestaurantLoaded, hasRetaurants])

  
  useEffect(async() => {
    if (selectedRestaurantId !== "" && typeof formData.start_date === "string" && prevFromData.current !== formData) {
      // await getDailyOccupancy(formData.start_date, formData.end_date);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, updateLanguage]);

  const formatDateForYearlyData = (date) => {
    // Format the date as "YYYY-MM-DD" for matching the dummyYearlyData keys
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add leading zero
    const day = date.getDate().toString().padStart(2, "0"); // Add leading zero
    return `${year}-${month}-${day}`;
  };
  
  const getDynamicWeekDates = () => {
    const today = new Date();
    const dates = [];
    
    // Last 3 days
    for (let i = -3; i < 0; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      dates.push(formatDateForYearlyData(date)); // Use format for the yearly data
    }
    
    // Current day
    dates.push(formatDateForYearlyData(today));
    
    // Next 3 days
    for (let i = 1; i <= 3; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      dates.push(formatDateForYearlyData(date)); // Use format for the yearly data
    }
  
    return dates;
  };
  
  useEffect(() => {
    if (isRestaurantLoaded && !hasRetaurants) {              
      const dynamicDates = getDynamicWeekDates();
      let filteredData;
      // Check the formData and select the corresponding dataset
     if (formData?.month) {
        filteredData = dummyMonthlyData;
      } else if (formData?.week) {
        filteredData = dummyWeeklyData;
      } else {
        filteredData = dummyYearlyData;
      }
      
      // Filter the dummy yearly data to match dynamic dates
      const filteredYearlyData = filteredData?.map((item) => {
        const updatedItem = { ...item };
  
        // Remove any dates that are not in the dynamic range
        Object.keys(updatedItem).forEach((key) => {
          if (key !== "name" && !dynamicDates.includes(key)) {
            delete updatedItem[key]; // Remove irrelevant dates
          }
        });
  
        // Ensure that all dynamic dates are set to 0 if they are missing from the data
        dynamicDates.forEach((date) => {
          if (!updatedItem[date]) {
            updatedItem[date] = 0; // Fallback to 0 if the date doesn't exist in the data
          }
        });
  
        return updatedItem;
      });
  
      // Set the filtered data to tableData
      setTableData(filteredYearlyData);
    }
  }, [formData, isRestaurantLoaded, hasRetaurants]);

  // temporary we keep the demo data for real account as well
  useEffect(() => {
      const dynamicDates = getDynamicWeekDates();
      let filteredData;
      // Check the formData and select the corresponding dataset
     if (formData?.month) {
        filteredData = dummyMonthlyData;
      } else if (formData?.week) {
        filteredData = dummyWeeklyData;
      } else {
        filteredData = dummyYearlyData;
      }
      
      // Filter the dummy yearly data to match dynamic dates
      const filteredYearlyData = filteredData?.map((item) => {
        const updatedItem = { ...item };
  
        // Remove any dates that are not in the dynamic range
        Object.keys(updatedItem).forEach((key) => {
          if (key !== "name" && !dynamicDates.includes(key)) {
            delete updatedItem[key]; // Remove irrelevant dates
          }
        });
  
        // Ensure that all dynamic dates are set to 0 if they are missing from the data
        dynamicDates.forEach((date) => {
          if (!updatedItem[date]) {
            updatedItem[date] = 0; // Fallback to 0 if the date doesn't exist in the data
          }
        });
  
        return updatedItem;
      });
  
      // Set the filtered data to tableData
      setTableData(filteredYearlyData);
  }, [formData]);

  // useEffect(()=> {
  //   if (isRestaurantLoaded && !hasRetaurants) {
  //     //  const occupancyByDays = getDummyFilteredData(occupancyGuestsDummy,formData, selectedRestaurant?.timezone)
  //     //  transformTableData(occupancyByDays);
  //     //  setLineChartData(() => sumDailyOccupancy(occupancyByDays, formData));
  //     setTableData(dummyYearlyData)
  //   }
  // },[formData, isRestaurantLoaded, hasRetaurants])

  const transformTableData = (dailyOccupancies) => {
    const finalData = dailyOccupancies.map((ele) => {
      // Extract the month from the date and translate it
      const formattedDate = moment(ele.date).format("DD MMMM YYYY");
      const month = moment(ele.date).format("MMMM");
      const translatedMonth = t(month);
  
      // Replace the month in the formatted date with the translated month
      const translatedDate = formattedDate.replace(month, translatedMonth);
      return {
        date: translatedDate,
        ...getGuests(ele),
      };
    });
    setTableData(finalData);
  };

  const getDailyOccupancy = async (startDate, endDate) => {
    
    if (selectedRestaurantId === "") {
      return;
    }

    setLoading(true);
    formatData();
    generateTableColumns(cols);
    try {
      const result = await request.get(
        "occupancy/guests",
        {
          ...timezoneFormat(startDate, endDate, selectedRestaurant?.timezone),
          restaurant_id: selectedRestaurantId,
        },
        true,
        true,
        true
      );
      const occupancyByDays = parseData(
        result.days,
        selectedRestaurant?.timezone
      );
      transformTableData(occupancyByDays);
      setLineChartData(() => sumDailyOccupancy(occupancyByDays, formData));
      setLoading(false);
    } catch (error) {
      console.log(error);
      if (error?.status !== 499) {
        setError(DEFAULT_ERROR_MESSAGE);
        setLoading(false);
      }
    }
  };

  return (
    <div className="leftcontent leftcontent-occupancy">
      <div className="main-container">
        <div className="card" style={{ height: "38% !important"}}>
          <div className="card card-body-custom ">
            <div className="card-header d-flex align-items-center justify-content-between">
              <h2 style={{ color: "#6353ea", fontSize: "21px"}}>{t("Yearly occupancy")}</h2>
            </div>
            <div className="card-body">
              <CustomTable columns={tableColumns} data={tableData} />
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card h-100">
            <div className="card-body">
             <Comparison
                formData={isRestaurantLoaded && !hasRetaurants ? {...formData, start_date: formData.start_date, end_date: formData.end_date} : formData}
                lineChartData={lineChartData}
              />       
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftYearlyOccupancy;
