import React, { useState, useEffect } from "react";
import convertUnits from 'convert-units';

import {
  Modal,
  Button,
  Row,
  Col,
  Container,
  Card,
  Badge,
  Form,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useLoading } from "contexts/LoadingContextManagement";
import { useUserData } from "contexts/AuthContextManagement";
import request from "services/request";

import SaveImage from "assets/images/saveImage.png";
import EyeIcon from "assets/images/icon/full-eye.svg";

import QuantityContainer from "components/quantityBtnContainer";

function Text({caption, style}) {
  let customStyle = {...style, border: 'none', width: "auto"}
  return (
    <>
      <span style={customStyle} className="add-restaurants-input-title form-control">{caption}</span>
    </>
  );
}

function OrderSaveModal({
  onHide,
  show,
  data: { selectedIngredient },
  isUploadedModal = true,
}) {
  const { t } = useTranslation();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isUploaded, setIsUploaded] = useState(false);
  const { setLoading, setError } = useLoading();
  const [files, setFiles] = useState([]);
  const [fd, setFd] = useState({ allergens: [], details: [] });
  const [quantity, setQuantity] = useState(selectedIngredient?.stock?.unit_stock);
  const {
    selectedRestaurantId,
    isRestaurantLoaded,
    hasRetaurants,
    setSampleRestaurantModal,
  } = useUserData();

  useEffect(() => {
    setFd({ allergens: [], details: [] });
    setFiles([]);
    setIsSubmitDisabled(true);
    setIsUploaded(false);
  }, [show]);

  useEffect(() => {
    if (
      fd?.name &&
      fd?.cost_excl_tax &&
      fd?.category &&
      fd?.unit
    ) {
      setIsSubmitDisabled(false);
      return;
    }
    setIsSubmitDisabled(true);
  }, [fd]);

  useEffect(() => {
    if (selectedIngredient) {
      setFd({
        ...selectedIngredient,
        cost_excl_tax: (selectedIngredient.cost_excl_tax / selectedIngredient.details?.find(p => p.is_default === true)?.provider_ingredient?.recipe_unit_quantity)?.toFixed(4),
        allergens: selectedIngredient?.allergens?.map((a) => a.id),
      });
    }
  }, [selectedIngredient]);

  const setCurrentValue = (value) => {
    setQuantity(value);
    setFd({ ...fd, stock: { unit_stock: value } });
  };

  const onSubmit = async (ev) => {
    ev.preventDefault();
    try {
      if (isRestaurantLoaded && !hasRetaurants) {
        setSampleRestaurantModal(true);
        onHide();
        return;
      }
      setIsSubmitDisabled(true);
      setLoading(true);
      const payload = {
        restaurant_id: selectedRestaurantId,
        ...fd,
        cost_excl_tax: fd.cost_excl_tax * fd.details?.find(p => p.is_default)?.provider_ingredient.recipe_unit_quantity,
        unit: fd?.converted_unit ?? fd?.unit,
        details: fd?.details.map(item => ({provider_ingredient_id: item.provider_ingredient.id, is_default: item.is_default }))
      };

      if(fd.details.length === 0) {
        setError(t("Please Select Provider ingredient to create ingredient"))
        return
      }

      if(!fd.details?.some((item => item.is_default))){
        setError("Please Select Default value")
        return
      }
      delete payload.converted_unit;

      if (!selectedIngredient) {
        const result = await request.post("/ingredients", payload);
        throw Error(result);
      }
      delete payload.id;
      delete payload.provider_name;
      delete payload.createdAt;
      delete payload.updatedAt;
      delete payload.label;
      delete payload.value;
      delete payload.deletedAt;
      if (payload.image_path !== "" && payload.image_path !== null) {
        delete payload.image_path;
      }
      const resultQuery = await request.patch(
        `/ingredients/${selectedIngredient.id}`,
        payload
      );
      throw Error(resultQuery);
    } catch (error) {
      console.log(error);
      setIsSubmitDisabled(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className="order-sent-labo"
      backdropClassName="add-ingredient-backdrop"
    >
      <div style={{ width: "90%", height: "447px", backgroundColor: "#873CFC", position: "absolute", top: "-300px", left: "50%", transform: "translate(-50%, 0)", zIndex: "-1", padding: "30px 100px", borderRadius: "35px"}}>
        <img width={{width: "100%"}} height={{height: "100%"}} src={SaveImage} alt="SaveImage" />
      </div>
     <div className="order-sent-modal-content">
        <h1 className="order-sent-modal-title">{t("MODIFICATIONS VALIDATED !")}</h1>
        <p className="order-sent-modal-text">
          {t("The quantities you changed have been successfully saved")}
        </p>
        <div className="order-sent-modal-button-container">
          <Button onClick={onHide} className="order-sent-modal-button">
            {t("Finish")}
          </Button>
        </div>
     </div>
    </Modal>
  );
}

export default OrderSaveModal;
