// const subMenu = {
//     // "Finance": ["Finance", "Sales channels"],
//     "Stock": ["Stock", "Inventories"],
//     "Occupancy": ["Daily occupancy", "Yearly occupancy" ]
// };

// subMenu.js
export const getSubMenu = (hasRetaurants) => {
    const subMenu = {
      Stock: ["Stock", "Inventories"],
      Occupancy: ["Daily occupancy", "Yearly occupancy"],
    };
  
    if (hasRetaurants) {
      delete subMenu["Occupancy"];
    }
  
    return subMenu;
  };
  