import React, { useState, useEffect } from "react";
import convertUnits from 'convert-units';

import {
  Modal,
  Button,
  Row,
  Col,
  Container,
  Card,
  Badge,
  Form,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useLoading } from "contexts/LoadingContextManagement";
import { useUserData } from "contexts/AuthContextManagement";
import request from "services/request";

import UploadedIcon from "assets/images/uploaded_meal.png";
import QuantityContainer from "components/quantityBtnContainer";

function Text({caption, style}) {
  let customStyle = {...style, border: 'none', width: "auto"}
  return (
    <>
      <span style={customStyle} className="add-restaurants-input-title form-control">{caption}</span>
    </>
  );
}

function CustomModal({
  onHide,
  show,
  data: { selectedIngredient },
  isUploadedModal = true,
  setCurrentItems,
  currentItems
}) {
  const { t } = useTranslation();
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isUploaded, setIsUploaded] = useState(false);
  const { setLoading, setError } = useLoading();
  const [files, setFiles] = useState([]);
  const [fd, setFd] = useState({ allergens: [], details: [] });
  const [quantity, setQuantity] = useState(selectedIngredient?.stock?.unit_stock);

  const {
    selectedRestaurantId,
    isRestaurantLoaded,
    hasRetaurants,
    setSampleRestaurantModal,
  } = useUserData();

  useEffect(() => {
    setFd({ allergens: [], details: [] });
    setFiles([]);
    setIsSubmitDisabled(true);
    setIsUploaded(false);
  }, [show]);

  useEffect(() => {
    if (
      fd?.name &&
      fd?.cost_excl_tax &&
      fd?.category &&
      fd?.unit
    ) {
      setIsSubmitDisabled(false);
      return;
    }
    setIsSubmitDisabled(true);
  }, [fd]);

  useEffect(() => {
    if (selectedIngredient) {
      setFd({
        ...selectedIngredient,
        cost_excl_tax: (selectedIngredient.cost_excl_tax / selectedIngredient.details?.find(p => p.is_default === true)?.provider_ingredient?.recipe_unit_quantity)?.toFixed(4),
        allergens: selectedIngredient?.allergens?.map((a) => a.id),
      });
    }
  }, [selectedIngredient]);

  const sendOrderHandler = () => {
    let updatedCurrentItems = currentItems.map(item => {
      if(item.id === selectedIngredient.id) {
        return {...item, order_status: "sent", delievry_status: "active"}
      }
      return item
    })
    setCurrentItems(updatedCurrentItems)
    onHide();
  }

  const setCurrentValue = (value) => {
    setQuantity(value);
    setFd({ ...fd, stock: { unit_stock: value } });
  };

  const onSubmit = async (ev) => {
    ev.preventDefault();
    try {
      if (isRestaurantLoaded && !hasRetaurants) {
        setSampleRestaurantModal(true);
        onHide();
        return;
      }
      setIsSubmitDisabled(true);
      setLoading(true);
      const payload = {
        restaurant_id: selectedRestaurantId,
        ...fd,
        cost_excl_tax: fd.cost_excl_tax * fd.details?.find(p => p.is_default)?.provider_ingredient.recipe_unit_quantity,
        unit: fd?.converted_unit ?? fd?.unit,
        details: fd?.details.map(item => ({provider_ingredient_id: item.provider_ingredient.id, is_default: item.is_default }))
      };

      if(fd.details.length === 0) {
        setError(t("Please Select Provider ingredient to create ingredient"))
        return
      }

      if(!fd.details?.some((item => item.is_default))){
        setError("Please Select Default value")
        return
      }
      delete payload.converted_unit;

      if (!selectedIngredient) {
        const result = await request.post("/ingredients", payload);
        throw Error(result);
      }
      delete payload.id;
      delete payload.provider_name;
      delete payload.createdAt;
      delete payload.updatedAt;
      delete payload.label;
      delete payload.value;
      delete payload.deletedAt;
      if (payload.image_path !== "" && payload.image_path !== null) {
        delete payload.image_path;
      }
      const resultQuery = await request.patch(
        `/ingredients/${selectedIngredient.id}`,
        payload
      );
      throw Error(resultQuery);
    } catch (error) {
      setIsSubmitDisabled(false);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      className="add-ingredient-labo"
      backdropClassName="add-ingredient-backdrop"
    >
      <Modal.Header className="add-restaurants-modal-header" closeButton>
        {!isUploaded && (
          <Modal.Title className="add-restaurants-modal-title">
            Order number 14U24U2I48
          </Modal.Title>
        )}
      </Modal.Header>

      <Modal.Body>
        <Container className="p-0">
          {isUploadedModal && isUploaded && (
            <div className="d-flex justify-content-center flex-column  text-center upload-container">
              <div>
                <img src={UploadedIcon} alt="..." />
              </div>
              <div className="heading mt-5">
                <label>{t("Order uploaded")} !</label>
              </div>
              <div className="subheading mt-2 mb-5">
                <label>
                  {t("Your Order has been successfully uploaded")}.
                </label>
              </div>
              <div>
                <Button className="add-restaurant-confirm-btn" onClick={onHide}>
                  {t("OK")}
                </Button>
              </div>
            </div>
          )}
          {!isUploaded && (
            <Form noValidate className="ps-0 pe-0" onSubmit={onSubmit}>
              <Row className="mt-4">
                  <Col lg={12}>
                    <Card className="mb-0 bg-transparent">
                      <div className="p-4 allergens-container">

                        <div className="row custom-table h-100">
                          <div className="col-lg-12 h-100 p-0">
                            <div className="tablescroll">
                              <table className="table" style={{backgroundColor: "transparent"}}>
                                <thead>
                                  <tr>
                                    <td
                                      className="text-center"
                                      style={{ width: "30%", textAlign: "center" }}
                                    >
                                      {t("Name")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "30%" }}
                                    >
                                      {t("Inventory")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "25%" }}
                                    >
                                      {t("Theoretical stock")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "25%" }}
                                    >
                                      {t("Inventory variance")}
                                    </td>
                                    <td
                                      className="text-center"
                                      style={{ width: "25%" }}
                                    >
                                      {t("Price")}
                                    </td>
                                  </tr>
                                </thead>
                                <tbody>
                                    <tr
                                      className="flex justify-between"
                                    >
                                      <td style={{ height: "50px", textAlign: "center" }}>
                                        <Text style={{width: "100%", display: "inline-block", whiteSpace: "initial", backgroundColor: "transparent"}}  caption={selectedIngredient?.name} />
                                      </td>
                                      <td className="text-center" style={{ width: "200px" }}>
                                         <QuantityContainer currentValue={quantity} setCurrentValue={setCurrentValue} />
                                      </td>
                                      <td className="text-center" style={{ width: "100px" }}>
                                        <Text style={{ backgroundColor: "transparent"}} caption={selectedIngredient?.theoretical_stock} />
                                      </td>
                                      <td className="text-center" style={{ width: "100px" }}>
                                        <Text style={{ backgroundColor: "transparent" }} caption={quantity - selectedIngredient.theoretical_stock} />
                                      </td>
                                      <td className="text-center" style={{ width: "100px" }}>
                                        <Text style={{ backgroundColor: "transparent"}} caption={selectedIngredient?.cost_excl_tax} />
                                      </td>
                                    </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>

              <Modal.Footer className="labo-order-modal-footer">
                <Button
                  type="submit"
                  className="labo-order-modal-btn"
                  style={{ backgroundColor: "#873CFC", width: "50%"}}
                  onClick={sendOrderHandler}
                  // disabled={selectedIngredient.order_status === "sent"}
                >
                  {t("Save")}
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default CustomModal;
