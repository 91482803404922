import React, { useState } from "react";
import moment from "moment";

import LeftSide from "./leftSide";
import RightSide from "./rightSide";

import LeftDailyOccupancy from "./leftDailyOccupancy";
import LeftYearlyOccupancy from "./leftYearlyOccupancy";
import RightYearlyOccupancy from "./rightYearlyOccupancy";
import RightDailyOccupancy from "./rightDailyOcccupancy";
import { useSubMenuData } from "contexts/SidebarContextManagment";
import { useUserData } from "contexts/AuthContextManagement";

import "./index.scss";

function Forecast() {
  const { selectedOccupancySubMenu } = useSubMenuData();
  const {
    selectedRestaurantId,
    selectedRestaurant,
    isRestaurantLoaded,
    hasRetaurants,
  } = useUserData();

  const [formData, setformData] = useState({
    start_date: moment().subtract(3, 'days').format('YYYY-MM-DD'),
    end_date: moment().add(3, 'days').format('YYYY-MM-DD'),
  });

  const onApply = (params) => setformData({ ...params });

  return (
    <>
      {!hasRetaurants && isRestaurantLoaded ? (
        <>
          {selectedOccupancySubMenu == "Daily occupancy" && <LeftDailyOccupancy formData={formData} />}
          {selectedOccupancySubMenu == "Daily occupancy" && <RightDailyOccupancy onApply={onApply} formData={formData} />}
          {selectedOccupancySubMenu == "Yearly occupancy" && <LeftYearlyOccupancy formData={formData} />}
          {selectedOccupancySubMenu == "Yearly occupancy" && <RightYearlyOccupancy onApply={onApply} formData={formData} />}
        </>
      ) : (
        <>
          <LeftSide formData={formData} />
          <RightSide onApply={onApply} formData={formData} />
        </>
      )}
    </>
  );
}

export default Forecast;
